@mixin angular($style, $align, $size) {
    &.#{$align}-#{$style}-#{$size} {

        // Polygon configurations
        @if $style==1 {
            @if $align=='left' {
                clip-path: polygon(0 0, 12% 0, 100% 100%, 0 100%);
            }

            @if $align=='right' {
                clip-path: polygon(88% 0, 100% 0, 100% 100%, 0% 100%);
            }
        }

        @if $style==2 {
            @if $align=='left' {
                clip-path: polygon(0 0, 100% 0, 12% 100%, 0 100%);

                @if $size=='md' {
                    clip-path: polygon(0 0, 90% 0, 60% 100%, 0 100%);
                }

                @if $size=='lg' {
                    clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
                }
            }

            @if $align=='right' {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 88% 100%);

                @if $size=='lg' {
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
                }

                @if $size=='md' {
                    clip-path: polygon(10% 0, 100% 0, 100% 100%, 40% 100%);
                }
            }
        }

        @if $style==3 {
            @if $align=='left' {
                clip-path: polygon(0 0, 0 100%, 100% 0);
            }

            @if $align=='right' {
                clip-path: polygon(100% 0, 0 100%, 100% 100%);
            }
        }

        @if $style==4 {
            // Bottom
            @if $align=='left' {
                clip-path: polygon(0 0, 100% 61%, 100% 100%, 0% 100%);
            }

            @if $align=='right' {
                clip-path: polygon(100% 0, 0 0, 100% 100%);
            }
        }

        @if $style==5 {
            // top
            @if $align=='left' {
                clip-path: polygon(0 0, 100% 0, 100% 84%, 0 37%);
            }

            @if $align=='right' {
                clip-path: polygon(0 0, 100% 0, 100% 37%, 0 84%);
            }
        }
    }
}