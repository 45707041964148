// -- Vars --
$font-family: 'Squada One',
cursive;
$font-family--title: 'Racing Sans One',
cursive;

// -- Layout --
// $content-width--home: 1000px;
// $content-width: 700px;
// $padding-top: 12px;
// $logo-height: 35px;
// $logo-width: 250px;
$navbar-height: 58px;
// $navbar-max-width: 1600px;
// $footer-height: 60px;
// $page-padding-top: 45px;

// -- Colours --
// Note: Initial implementation as dark mode, light mode not considered
$brand--primary--dark: #000000;
$brand--primary--light: lighten($brand--primary--dark, 10%);
$brand--secondary--dark: #ff1616;
$brand--secondary--light: lighten($brand--secondary--dark, 10%);
$brand--tertiary--dark: darken($brand--secondary--dark, 20%);
$brand--tertiary--light: darken($brand--secondary--light, 20%);

$bg--light: white;
$bg--2--light: darken($bg--light, 10%);
$bg--3--light: darken($bg--light, 20%);
$bg--dark: #121414; //#030302;
$bg--2--dark: #1b1e1e; //#111312;
$bg--3--dark: rgba(black, 0.5);

// $bg--dark--top: 0;
// $bg--dark--bottom: 0;
// $bg--dark--gradient: linear-gradient(180deg, $bg--dark--top 0%, $bg--dark--bottom 100%);
$text--base--dark: white;
$text--base--light: black;

$text--dark: lighten($text--base--dark, 10%);
$text--light: darken($text--base--light, 10%);

$text--2--dark: lighten($text--base--dark, 20%);
$text--2--light: darken($text--base--light, 20%);

$text--3--dark: lighten($text--base--dark, 30%);
$text--3--light: darken($text--base--light, 30%);

// -- Text --
$font-size--base: 22px;
$font-size--base--mobile: $font-size--base - 2;
$font-size--secondary: 18px;
$font-size--tertiary: 15px;
$font-size--page-title: $font-size--base * 2.6;
$font-size--page-title--md: $font-size--base * 1.5;
$font-size--page-title--mobile: 26px;

$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--semibold: 500;
$font-weight--bold: 700;

$line-height--base: 1.6;
$line-height--compact: 0.8;
$line-height--title: 1.4;

$margin--title: 1.4em 0;

// Mantine Breakpoints
$xs: '576px';
$sm: '768px';
$md: '992px';
$lg: '1200px';
$xl: '1400px';