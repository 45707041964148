@mixin bg-colour($colour) {
    &.#{$colour} {
        @include themed() {
            background-color: t($colour) !important;
        }
    }
}

@mixin border-colour($colour) {
    &.#{$colour} {
        @include themed() {
            border-color: t($colour) !important;
        }
    }
}
