// Use variables to use class names to ensure they always match correctly
$brand-primary: 'brand-primary';
$brand-secondary: 'brand-secondary';
$brand-tertiary: 'brand-tertiary';
$bg-primary: 'bg-primary';
$bg-secondary: 'bg-secondary';
$bg-tertiary: 'bg-tertiary';
$bg-black: 'bg-black';
$text-primary: 'text-primary';
$text-secondary: 'text-secondary';
$text-tertiary: 'text-tertiary';
$link: 'link';
// $logo: 'logo'; // Todo: Add

// Themes
$themes: (default: (brand-primary: $brand--primary--dark,
    brand-secondary: $brand--secondary--dark,
    brand-tertiary: $brand--tertiary--dark,
    bg-primary: $bg--dark,
    bg-secondary: $bg--2--dark,
    bg-tertiary: $bg--3--dark,
    bg-black: black,
    text-primary: $text--dark,
    text-secondary: $text--2--dark,
    text-tertiary: $text--3--dark,
    link: $brand--primary--dark),
  light: (bg-primary: $bg--light,
    bg-secondary: $bg--2--light,
    bg-tertiary: $bg--3--light,
    text-primary: $text--light,
    text-secondary: $text--2--light,
    text-tertiary: $text--3--light,
    link: $brand--primary--light,
  ));

// Themed mixin, use @include themed() { } when wanting to use a theme item
@mixin themed() {

  @each $theme,
  $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
