// FORMAT - {SHAPE}-{ALIGNMENT}-{STYLE}-{SIZE}
// Examples: angular-left-1-sm, angular-right-2-lg

// NOTES
// wrapper > shape, avatar, alignment
// wrapper needs - size, alignment
// shape needs - polygon(style), colour, absolute
.tile-style {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.tile-style-content {
    flex: 1;

    &.lg-media-1 {
        display: flex;
        justify-content: center;
    }

    &.lg-media-2 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.angular-style-wrapper {
    position: relative;
    display: flex;
    // height: 100%;
}

.angular-shape {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    // Supported colours
    @include bg-colour($bg-primary);
    @include bg-colour($bg-secondary);
    @include bg-colour($bg-tertiary);
    @include bg-colour($bg-black);
    @include bg-colour($brand-primary);
    @include bg-colour($brand-secondary);
    @include bg-colour($brand-tertiary);

    // &:has(.angular-avatar-wrapper) {
    //     position: absolute !important;
    // }
}

.angular-media-wrapper,
.angular-avatar-wrapper {
    z-index: 1;
}

@mixin angularStyle($align, $style, $size) {
    $angularStyle: "#{$align}-#{$style}-#{$size}";

    .angular-style-wrapper {
        @include angularAlignSize($style, $align, $size);
    }

    .angular-shape {
        @include angular($style, $align, $size);
    }
}

@mixin angularAlignSize($style, $align, $size) {
    &.#{$align}-#{$style}-#{$size} {
        @if $align=='left' {
            top: 0;
            left: 0;

            &:has(.angular-avatar-wrapper) {
                display: flex;
                align-items: flex-end;
                align-items: flex-end;
                justify-content: center;
                flex-direction: column;

                .angular-avatar-wrapper {
                    right: -5em;
                    position: relative;

                    @media (max-width: $sm) {
                        right: auto;
                        align-self: center;

                        @if $style==4 {
                            padding-bottom: 14px;
                        }                        

                        @if $style==5 {
                            padding-top: 14px;
                        }
                    }
                }
            }
        }

        @if $align=='right' {
            top: 0;
            right: 0;

            &:has(.angular-avatar-wrapper) {
                .angular-avatar-wrapper {
                    right: 4em;
                    position: relative;

                    @media (max-width: $sm) {
                        right: 0;
                        display: flex;
                        justify-content: center;
                        align-self: center;
                        padding-top: 14px;
                        width: 100%;

                        @if $style==4 {
                            padding-bottom: 14px;
                        }  
                        @if $style==5 {
                            padding-top: 14px;
                        }
                    }
                }
            }
        }

        width: 250px;

        @media (max-width: $sm) {
            // Default configuration for all styles when mobile.
            // TODO: Improve this method.
            width: 100%;
            min-height: 50px;
        }

        @if $size=='sm' {
            width: 200px;
        }

        @if $size=='lg' {
            width: 300px;
        }

        @if $size=='xl' {
            width: 450px;
        }
    }


    // &.style-#{$style} {
    //     content: 'test';
    // }

    // &.#{$align} {
    //     @if $align=='left' {
    //         top: 0;
    //         left: 0;

    //         &:has(.angular-avatar-wrapper) {
    //             display: flex;
    //             align-items: flex-end;
    //             align-items: flex-end;
    //             justify-content: center;
    //             flex-direction: column;

    //             @if $style==5 {
    //                 top: 2em;
    //             }

    //             .angular-avatar-wrapper {
    //                 right: -5em;
    //                 position: relative;

    //                 @media (max-width: $sm) {
    //                     right: auto;
    //                     top: 1em;
    //                     align-self: center;                        
    //                 }
    //             }
    //         }
    //     }

    //     @if $align=='right' {
    //         top: 0;
    //         right: 0;

    //         &:has(.angular-avatar-wrapper) {
    //             .angular-avatar-wrapper {
    //                 right: 4em;
    //                 position: relative;
    //             }
    //         }
    //     }
    // }

    // &.#{$size} {
    //     width: 250px;

    //     @media (max-width: $sm) {
    //         width: 100%;
    //     }

    //     @if $size=='sm' {
    //         width: 200px;
    //     }

    //     // @if $size=='md' {
    //     //     width: 12em;
    //     // }

    //     @if $size=='lg' {
    //         width: 300px;
    //     }

    //     @if $size=='xl' {
    //         width: 450px;
    //     }
}


// SUPPORTED STYLES
// Todo: Make these loop through constants
@include angularStyle('left', 1, 'sm');
@include angularStyle('right', 1, 'sm');

@include angularStyle('left', 2, 'sm');
@include angularStyle('right', 2, 'sm');

@include angularStyle('left', 2, 'md');
@include angularStyle('right', 2, 'md');

@include angularStyle('left', 2, 'lg');
@include angularStyle('right', 2, 'lg');

@include angularStyle('left', 3, 'sm');
@include angularStyle('right', 3, 'sm');

@include angularStyle('left', 4, 'sm');
@include angularStyle('right', 4, 'sm');

@include angularStyle('left', 5, 'sm');
@include angularStyle('right', 5, 'sm');
