.avatar {
    background: black;
    border-radius: 200px;
    overflow: overlay;
    border-style: solid;
    border-width: 18px;
    width: fit-content;

    &.md {
        @media (max-width: $sm) {
            margin: 0;
            border-width: 12px;
        }
    }

    &.lg {
        margin-left: -70px; // Inconsistently using margin vs positioning (top/left etc.) to position avatar

        @media (max-width: $sm) {
            margin: 0;
            border-width: 14px;
        }
    }

    &.xl {
        margin-left: -80px; // Inconsistently using margin vs positioning (top/left etc.) to position avatar

        @media (max-width: $sm) {
            margin: 0;
            border-width: 14px;
        }
    }

    .avatar-img {
        vertical-align: middle;
        object-fit: cover;

        &.md {
            width: 200px;
            height: 200px;

            @media (max-width: $sm) {
                width: 120px;
                height: 120px;
            }
        }

        &.lg {
            width: 265px;
            height: 265px;

            @media (max-width: $sm) {
                width: 160px;
                height: 160px;
            }

            // @media (min-width: $) and (max-width: 50em) {
        }

        &.xl {
            width: 320px;
            height: 320px;

            @media (max-width: $sm) {
                width: 200px;
                height: 200px;
            }

            // @media (min-width: $) and (max-width: 50em) {
        }
    }

    // Supported colours
    @include border-colour($bg-primary);
    @include border-colour($bg-secondary);
    @include border-colour($bg-tertiary);
    @include border-colour($bg-black);
    @include border-colour($brand-primary);
    @include border-colour($brand-secondary);
    @include border-colour($brand-tertiary);
}