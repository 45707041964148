.tile {
    width: 100%;
    min-height: 48px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    // Supported colours
    @include bg-colour($bg-primary);
    @include bg-colour($bg-secondary);
    @include bg-colour($bg-tertiary);
    @include bg-colour($bg-black);
    @include bg-colour($brand-primary);
    @include bg-colour($brand-secondary);
    @include bg-colour($brand-tertiary);
}

.style-static-overlay {
    position: static;
}
