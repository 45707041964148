html {
    overflow-y: auto;
    overflow-x: hidden;
}

html,
body {
    font-family: $font-family !important;
    background: $brand--primary--dark !important;
    font-size: $font-size--base !important;
    line-height: $line-height--base !important;
    font-weight: normal !important;
    margin: 0 !important;
}

p {
    margin: 0;
}

h1 {
    margin: 1.2em 0;
    font-size: $font-size--page-title !important;
    font-family: $font-family--title !important;
    font-weight: $font-weight--bold !important;
    line-height: $line-height--title !important;
}

h3 {
    font-weight: 400;
    font-size: 36px;
    margin: 0 !important;
    margin-bottom: 0.8em !important;
}

h4 {
    font-weight: 400;
    font-size: 32px;
    margin-bottom: 0.8em;
    margin-top: 0;
}

.theme {
    height: 100vh;
    width: 100%;
}

.base {
    @include themed() {
        color: t($text-primary);
        background: t($bg-primary);
    }

    height: 100%;
}
