.underline-animation-wrapper {
    transition: 0.25s;
    cursor: pointer;

    .hover-underline-animation {
        display: inline-block;
        position: relative;
        color: $text--dark;

        &.light {
            color: $text--light;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $brand--secondary--dark;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
        }
    }

    &:hover {
        transform: translateY(-0.25em);

        .hover-underline-animation {
            &.light {
                &::after {
                    background-color: $brand--primary--light !important;
                }
            }

            &::after {
                background-color: $brand--secondary--dark;
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }

        &:focus {
            transform: translateY(-0.25em);
        }
    }

    &::after {
        .hover-underline-animation {
            &::after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }
    }
}