.splash-title {
    font-size: $font-size--page-title;
    font-family: $font-family--title;
    font-weight: $font-weight--bold;
    text-align: center;
    line-height: $line-height--title;
    text-shadow: black 5px 5px;
}

.splash-content {
    font-size: $font-size--base;
    font-weight: $font-weight--light;
    text-align: center;
    line-height: $line-height--compact;
}